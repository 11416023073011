<template>
  <v-container fluid>
    <v-card class="px-0 px-md-5 py-7 rounded-xl elevation-0">
      <v-row class="mt-3 mb-15">
        <v-col cols="12" md="6" lg="3" class="pb-0">
          <analytic-card
            title="Registros asignados"
            :description="indicators.assignedItems"
          />
        </v-col>
        <v-col cols="12" md="6" lg="3" class="pb-0">
          <analytic-card
            title="Registros por asignar"
            :description="indicators.unassignedItems"
          />
        </v-col>
        <v-col cols="12" md="6" lg="3" class="pb-0">
          <analytic-card
            title="Registros vendidos"
            :description="indicators.soldItems"
          />
        </v-col>
        <v-col cols="12" md="6" lg="3" class="pb-0">
          <analytic-card
            title="Registros totales"
            :description="indicators.totalItems"
          />
        </v-col>
      </v-row>
      <v-row class="mt-8 mb-15">
        <v-col cols="12">
          <my-shopping-table />
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import {mapActions, mapState} from "vuex";

import AnalyticCard from "@/components/dashboard/event/analytics/analytic-card";
import MyShoppingTable from "@/components/dashboard/billing/MyShoppingTable";
import BillingEventTable from "@/components/dashboard/billing/BillingEventTable";
import BillingAffiliationTable from "@/components/dashboard/billing/BillingAffiliationTable";

export default {
  components: {
    AnalyticCard,
    BillingEventTable,
    BillingAffiliationTable,
    MyShoppingTable,
  },
  data: () => ({
    tab: "billingEvent",
  }),
  computed: {
    ...mapState("user", ["indicators"]),
  },
  methods: {
    ...mapActions("purchase", ["fetchOrganizationPurchases"]),
  }
};
</script>