<template>
  <div>
    <v-data-table
      v-if="
        organizationPurchases.purchases &&
        organizationPurchases.purchases.length > 0
      "
      :headers="billingEventHeader"
      :items="organizationPurchases.purchases"
      :options.sync="options"
      :loading="billingLoader"
      :server-items-length="organizationPurchases.totalDocs"
      :footer-props="{
        itemsPerPageText: 'Filas por página',
        itemsPerPageOptions: [5, 10, 20, 50],
      }"
      class="elevation-0"
      disable-sort
    >
      <template v-slot:body="{ items }">
        <tbody>
          <tr
            v-for="(item, index) in items"
            :key="index"
            :class="[
              $vuetify.breakpoint.xsOnly
                ? 'v-data-table__mobile-table-row'
                : '',
              index % 2 == 1 ? 'amber lighten-5' : '',
            ]"
          >
            <td
              :class="
                $vuetify.breakpoint.xsOnly ? 'v-data-table__mobile-row' : ''
              "
            >
              <div
                class="font-weight-bold mr-2 v-data-table__mobile-row__header d-flex d-sm-none"
              >
                {{ billingEventHeader[0].text }}
              </div>
              <div
                :class="
                  $vuetify.breakpoint.xsOnly
                    ? 'v-data-table__mobile-row__cell'
                    : 'd-flex justify-left'
                "
              >
                {{ item._id }}
              </div>
            </td>
            <td
              :class="
                $vuetify.breakpoint.xsOnly ? 'v-data-table__mobile-row' : ''
              "
            >
              <div
                class="font-weight-bold mr-2 v-data-table__mobile-row__header d-flex d-sm-none"
              >
                {{ billingEventHeader[1].text }}
              </div>
              <div
                :class="
                  $vuetify.breakpoint.xsOnly
                    ? 'v-data-table__mobile-row__cell'
                    : 'd-flex justify-left'
                "
              >
                {{ formattedDate(item.updatedAt) }}
              </div>
            </td>
            <td
              :class="
                $vuetify.breakpoint.xsOnly ? 'v-data-table__mobile-row' : ''
              "
            >
              <div
                class="font-weight-bold mr-2 v-data-table__mobile-row__header d-flex d-sm-none"
              >
                {{ billingEventHeader[2].text }}
              </div>
              <div
                :class="
                  $vuetify.breakpoint.xsOnly
                    ? 'v-data-table__mobile-row__cell'
                    : 'd-flex justify-center'
                "
              >
                {{ item.itemQuantity }}
              </div>
            </td>
            <td
              :class="
                $vuetify.breakpoint.xsOnly ? 'v-data-table__mobile-row' : ''
              "
            >
              <div
                class="font-weight-bold mr-2 v-data-table__mobile-row__header d-flex d-sm-none"
              >
                {{ billingEventHeader[3].text }}
              </div>
              <div
                v-if="item.paymentInformation"
                :class="
                  $vuetify.breakpoint.xsOnly
                    ? 'v-data-table__mobile-row__cell'
                    : 'd-flex justify-left'
                "
              >
                {{ item.paymentInformation.buyer }}
              </div>
            </td>
            <td
              :class="
                $vuetify.breakpoint.xsOnly ? 'v-data-table__mobile-row' : ''
              "
            >
              <div
                class="font-weight-bold mr-2 v-data-table__mobile-row__header d-flex d-sm-none"
              >
                {{ billingEventHeader[4].text }}
              </div>
              <div
                v-if="item.paymentInformation"
                :class="
                  $vuetify.breakpoint.xsOnly
                    ? 'v-data-table__mobile-row__cell'
                    : 'd-flex justify-left'
                "
              >
                {{ item.paymentInformation.email }}
              </div>
            </td>
            <td
              :class="
                $vuetify.breakpoint.xsOnly ? 'v-data-table__mobile-row' : ''
              "
            >
              <div
                class="font-weight-bold mr-2 v-data-table__mobile-row__header d-flex d-sm-none"
              >
                {{ billingEventHeader[5].text }}
              </div>
              <div
                :class="
                  $vuetify.breakpoint.xsOnly
                    ? 'v-data-table__mobile-row__cell'
                    : 'd-flex justify-left'
                "
              >
                ${{ item.total | currency }}
              </div>
            </td>
            <td
              :class="
                $vuetify.breakpoint.xsOnly ? 'v-data-table__mobile-row' : ''
              "
            >
              <div
                class="font-weight-bold mr-2 v-data-table__mobile-row__header d-flex d-sm-none"
              >
                {{ billingEventHeader[6].text }}
              </div>
              <div
                :class="
                  $vuetify.breakpoint.xsOnly
                    ? 'v-data-table__mobile-row__cell'
                    : 'd-flex justify-left'
                "
              >
                <span
                  v-if="item.status == 'COMPLETED'"
                  class="green--text font-weight-bold"
                >
                  COMPLETADO</span
                >
                <span
                  v-else-if="item.status == 'IN_PROGRESS'"
                  class="blue--text font-weight-bold"
                  >PROGRESO</span
                >
                <span v-else class="red--text font-weight-bold">{{
                  item.state
                }}</span>
              </div>
            </td>
            <td
              :class="
                $vuetify.breakpoint.xsOnly ? 'v-data-table__mobile-row' : ''
              "
            >
              <div
                class="font-weight-bold mr-2 v-data-table__mobile-row__header d-flex d-sm-none"
              >
                {{ billingEventHeader[7].text }}
              </div>
              <div
                v-if="item.paymentInformation"
                :class="
                  $vuetify.breakpoint.xsOnly
                    ? 'v-data-table__mobile-row__cell'
                    : 'd-flex justify-center'
                "
              >
                {{
                  item.paymentInformation.paymentMethod == "CREDIT_CARD"
                    ? "TARJETA"
                    : item.paymentInformation.paymentMethod == "BANK_TRANSFER"
                    ? "SPEI"
                    : "EFECTIVO"
                }}
              </div>
            </td>
            <!-- <td
              :class="
                $vuetify.breakpoint.xsOnly ? 'v-data-table__mobile-row' : ''
              "
            >
              <div
                class="font-weight-bold mr-2 v-data-table__mobile-row__header d-flex d-sm-none"
              >
                {{ billingEventHeader[7].text }}
              </div>
              <div
                :class="
                  $vuetify.breakpoint.xsOnly
                    ? 'v-data-table__mobile-row__cell'
                    : 'd-flex justify-center'
                "
              >
                <v-btn
                  v-if="item.invoice"
                  class="custom-transform-class text-none font-weight-bold black--text"
                  color="accent"
                  rounded
                  small
                  elevation="0"
                  @click="downloadInvoice(item)"
                  :disabled="(!user.permissions.reportsDownload || !user.permissions.reportsDownload.update) && (user.role == 'ORGANIZATION_USER')"
                >
                  DESCARGAR
                </v-btn>
                <v-btn
                  v-else
                  class="custom-transform-class text-none font-weight-bold black--text"
                  color="secondary"
                  rounded
                  small
                  :disabled="
                    item.status == 'IN_PROGRESS' || isValidDate(item.updatedAt)
                  "
                  @click="openBillingDialog(item)"
                  elevation="0"
                >
                  FACTURAR
                </v-btn>
              </div>
            </td> -->
          </tr>
        </tbody>
      </template>
    </v-data-table>
    <v-dialog v-model="billingDialog" width="960">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="12" class="mt-2">
              <p class="text-center font-weight-bold headline mb-5 mt-5">
                Generar Factura
              </p>
            </v-col>
          </v-row>

          <v-form ref="billingForm" v-model="billingFormValid" lazy-validation>
            <v-row class="px-sm-6">
              <v-col cols="12" md="6" class="py-0">
                <v-text-field
                  v-model="billingData.purchaseId"
                  label="Id de transacción"
                  filled
                  rounded
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-text-field
                  v-model="billingData.email"
                  label="E-mail"
                  filled
                  rounded
                  :rules="emailRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-text-field
                  v-model="billingData.legalName"
                  label="Razón Social Nombre Fiscal"
                  filled
                  rounded
                  :rules="legalNameRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-text-field
                  v-model="billingData.taxId"
                  label="RFC"
                  filled
                  rounded
                  :rules="taxIdRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-text-field
                  v-model="billingData.street"
                  label="Dirección"
                  filled
                  rounded
                  :rules="streetRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-text-field
                  v-model="billingData.postalCode"
                  label="Código postal"
                  filled
                  rounded
                  :rules="postalCodeRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-select
                  v-model="billingData.paymentForm"
                  label="Forma de pago"
                  filled
                  rounded
                  :items="paymentFormsList"
                  :rules="paymentFormRules"
                ></v-select>
              </v-col>

              <v-col cols="12" md="6" class="py-0">
                <v-select
                  v-model="billingData.paymentMethod"
                  label="Método de pago"
                  filled
                  rounded
                  :items="paymentMethodsList"
                  :rules="paymentMethodRules"
                ></v-select>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-select
                  v-model="billingData.cfdi"
                  label="Uso de CFDI"
                  filled
                  rounded
                  :items="cfdiList"
                  :rules="cfdiRules"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <p class="title text-end mb-0">
                  Total:
                  <span class="secondary--text"
                    >${{ billingData.total | currency }}</span
                  >
                </p>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="grey lighten-3"
            class="font-weight-bold black--text mb-3"
            @click="closeBillingDialog"
            rounded
            elevation="0"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="accent"
            class="font-weight-bold black--text mb-3 mx-3"
            @click="createInvoice"
            rounded
            elevation="0"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import qs from "query-string";
import { mapState, mapActions, mapMutations } from "vuex";
import { validations } from "@/share/validations.js";
import Endpoints from "@/share/Endpoints";
import { downloadFile } from "@/utils";
import { CFDIs } from "@/share/constants";
export default {
  computed: {
    ...mapState("purchase", ["purchasesData"]),
    ...mapState("user", ["user"]),
  },
  data() {
    return {
      cfdiList: null,
      billingDialog: false,
      billingLoader: false,
      billingFormValid: false,
      options: {},
      searchData: {
        page: 1,
        itemsPerPage: 10,
      },
      organizationPurchases: {},
      billingData: {
        purchaseId: "",
        legalName: "",
        taxId: "",
        street: "",
        postalCode: "",
        paymentForm: "",
        paymentMethod: "",
        cfdi: "",
        email: "",
      },
      paymentFormsList: ["PUE Pago en una sola exhibición", "NA"],
      paymentMethodsList: [
        {
          text: "01 Efectivo",
          value: "CASH",
          disabled: true,
        },
        {
          text: "03 Transferencia electrónica de fondos ( Incluye SPEI)",
          value: "BANK_TRANSFER",
          disabled: true,
        },
        {
          text: "04 Tarjeta de crédito",
          value: "CREDIT_CARD",
          disabled: true,
        },
        {
          text: "28 Tarjeta de débito",
          value: "DEBIT_CARD",
          disabled: true,
        },
      ],
      /*cfdiList: [
        "P01 Por Definir.",
        "G03 Gastos en general",
        "D01 Honorarios médicos, dentales y gastos hopitalarios.",
        "D02 Gastos médicos por incapacidad o discapacidad.",
        "D04 Donativos.",
        "D05 Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación).",
        "D06 Aportaciones voluntarias al SAR.",
        "D07 Primas por seguros de gastos médicos.",
        "D08 Gastos de transportación escolar obligatoria.",
        "D09 Depósitos en cuenta para el ahorro de primas que tengan como base planes de pensiones.",
        "D10 Pagos por servicios educativos (colegiaturas)",
        "G02 Devoluciones o Descuentos o Bonificaciones",
        "I01 Construcciones.",
        "I02 Mobiliario y equipo de oficina por inversiones.",
        "I03 Equipo de transporte.",
        "I05 Dados, troqueles, moldes, maherramental.",
        "I06 Comunicaciones telefónicas.",
        "I07 Comunicaciones satelitales.",
        "I08 Otra maquinaria y equipo.",
      ],*/

      //Validaciones
      billinIdRules: validations.generalMValidation({
        required: true,
        name: "id de la transacción",
      }),
      legalNameRules: validations.generalFValidation({
        required: true,
        name: "razón social",
      }),
      taxIdRules: validations.generalMValidation({
        required: true,
        name: "RFC",
      }),
      streetRules: validations.generalFValidation({
        required: true,
        name: "dirección",
      }),
      postalCodeRules: validations.generalMValidation({
        required: true,
        name: "código postal",
      }),
      paymentFormRules: validations.generalFValidation({
        required: true,
        name: "forma de pago",
      }),
      paymentMethodRules: validations.generalMValidation({
        required: true,
        name: "método de pago",
      }),
      cfdiRules: validations.generalMValidation({
        required: true,
        name: "CFDI",
      }),
      emailRules: validations.generalMValidation({
        required: true,
        name: "e-mail",
      }),

      billingEventHeader: [
        {
          text: "ID de compra",
          align: "left",
          value: "date",
          width: "6%",
        },
        {
          text: "Fecha",
          align: "left",
          value: "date",
          width: "10%",
        },
        {
          text: "Cantidad créditos",
          align: "center",
          value: "quantity",
          width: "10%",
        },
        {
          text: "Nombres y Apellidos",
          align: "left",
          value: "name",
          width: "10%",
        },
        {
          text: "Correo electrónico",
          align: "left",
          value: "email",
          width: "10%",
        },
        {
          text: "Total",
          align: "left",
          value: "total",
          width: "10%",
        },
        {
          text: "Estado",
          align: "left",
          value: "state",
          width: "10%",
        },
        {
          text: "Método de pago",
          align: "center",
          value: "payment",
          width: "10%",
        },
        // {
        //   text: "Facturas",
        //   align: "center",
        //   value: "invoice",
        //   width: "10%",
        // },
      ],
    };
  },
  watch: {
    options: {
      async handler() {
        this.billingLoader = true;
        await this.searchOrganizationPurchases();
        this.billingLoader = false;
      },
      deep: true,
    },
  },
  methods: {
    //...mapActions("purchase", ["fetchOrganizationPurchases"]),
    ...mapActions("invoice", ["createInternalInvoice"]),
    ...mapMutations("loader", ["loading", "loaded"]),
    ...mapMutations("notification", ["show"]),
    async searchOrganizationPurchases() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      this.searchData.page = page;
      this.searchData.itemsPerPage = itemsPerPage;

      await this.fetchOrganizationPurchases();
    },
    async fetchOrganizationPurchases() {
      const endpoint = qs.stringifyUrl({
        url: Endpoints.purchases,
        query: {
          limit: this.searchData.itemsPerPage,
          page: this.searchData.page,
        },
      });
      const response = await this.axios.get(endpoint);
      if (response.status === 200) {
        this.organizationPurchases = response.data;
        //console.log(this.organizationPurchases);
      } else {
        //console.log(response);
      }
    },
    async createInvoice() {
      if (this.validateBillingForm()) {
        this.loading();
        try {
          const { purchaseId, paymentMethod, ...rest } = this.billingData;
          const payload = {
            purchase: {
              id: purchaseId,
              paymentMethod,
            },
            customer: { ...rest },
          };
          //console.log(payload);
          const response = await this.createInternalInvoice(payload);
          //const response = await this.axios.post(Endpoints.invoicesCreateInternal, payload);
          //console.log('create invoice', response);
          if (response.status == true) {
            await this.fetchOrganizationPurchases();
            this.show({
              text: "¡La factura se ha generado correctamente!",
              color: "primary",
            });
            this.billingDialog = false;
          }
          //if (response.status === 200) {
          //  const { status } = response.data;
          //}
        } catch (error) {
          console.log("error", error);
          this.show({
            text: "¡Ha ocurrido un problema al generar la factura!",
            color: "error",
          });
        } finally {
          this.loaded();
        }
      }
    },
    isInvalidDate(transactionDate) {
      let date = new Date();
      let firstDate = new Date(date.getFullYear(), date.getMonth(), 1);
      let lastDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      let currentDate = new Date(transactionDate);
      if (currentDate >= firstDate && currentDate <= lastDate) {
        return false;
      } else {
        return true;
      }
    },
    validateBillingForm() {
      if (this.$refs.billingForm.validate()) {
        this.snackbar = true;
        return true;
      }
      return false;
    },
    async downloadInvoice(item) {
      let filename = `${item.createdAt.substr(0, 10)} ${
        item.itemQuantity
      } registros.zip`;
      const payload = {
        invoiceInformation: { id: item.invoice },
      };
      const response = await this.axios.post(
        Endpoints.invoicesDownloadInternal,
        payload,
        { responseType: "arraybuffer" }
      );
      if (response.status > 199 && response.status < 300) {
        const { data } = response;
        downloadFile(data, "application/zip", filename);
      } else {
        this.show({
          text: "¡Ha ocurrido un problema al descargar la factura!",
          color: "error",
        });
      }
    },
    openBillingDialog(purchase) {
      //console.log(purchase);
      this.billingData.purchaseId = purchase._id;
      this.billingData.email = purchase.paymentInformation.email;
      this.billingData.total = purchase.total;

      this.paymentMethodsList.forEach((paymentForm) => {
        if (
          purchase.paymentInformation.paymentMethod == "CASH" &&
          paymentForm.value == "CASH"
        ) {
          paymentForm.disabled = false;
        } else if (
          purchase.paymentInformation.paymentMethod == "BANK_TRANSFER" &&
          paymentForm.value == "BANK_TRANSFER"
        ) {
          paymentForm.disabled = false;
        } else if (
          purchase.paymentInformation.paymentMethod == "CREDIT_CARD" &&
          (paymentForm.value == "CREDIT_CARD" ||
            paymentForm.value == "DEBIT_CARD")
        ) {
          paymentForm.disabled = false;
        }
      });

      this.billingDialog = true;
    },
    closeBillingDialog() {
      this.paymentMethodsList.forEach((paymentForm) => {
        paymentForm.disabled = true;
      });
      this.billingDialog = false;
    },
    isValidDate(date) {
      let transactionDate = new Date(date);
      let currentDate = new Date();

      // Obtener el primer día del mes
      let firstDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1
      );

      if (firstDate < transactionDate) {
        return false;
      } else {
        return true;
      }
    },
    formattedDate(date) {
      let currentDate = date.substr(0, 10);
      let [year, month, day] = currentDate.split("-");

      return `${day}/${month}/${year}`;
    },
  },
  async mounted() {
    this.cfdiList = CFDIs;
    await this.fetchOrganizationPurchases();
    console.log(this.purchasesData);
  },
};
</script>
<style scoped>
table,
tr,
td {
  border: none !important;
}
</style>