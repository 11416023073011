<!--Tarjeta que sale en las estadisticas de "TicketsAndSales.vue" (Se usa en el componenete analytic-list.vue)-->
<template>
  <v-card
    class="analytic-card rounded-xl"
    elevation="0"
    :color="color"
    rounded
    height="100px"
  >
    <v-row class="py-3 px-7" justify="center" align="center">
      <v-col cols="12" class="text-center text-md-right py-0 py-lg-3 px-1">
        <h2 class="subtitle-1 font-weight-bold">
          {{ title }}
        </h2>
        <h3 class="accent--text headline font-weight-bold">
          {{ description }}
        </h3>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
export default {
  props: {
    title: { type: String, required: true },
    description: { required: true },
    color: { type: String, default: "#fff7e5" },
  },
  computed: {
    getSize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "font-size:1.8em";
        case "sm":
          return "font-size:1.8em";
        case "md":
          return "font-size:1.7em";
        case "lg":
          return "font-size:2em";
        case "xl":
          return "font-size:2.3em";
      }
    },
  },
};
</script>
<style scoped>
.analytic-card {
  transform: scale(0.95);
  transition: all 0.5s;
}
.analytic-card:hover {
  transform: scale(1);
}
</style>