<template>
  <div>
    <v-data-table
      :headers="billingEventHeader"
      :items="purchasesData"
      :loading="billingLoader"
      item-key="name"
      class="elevation-0"
      v-if="purchasesData.length > 0"
      hide-default-footer
      disable-sort
    >
      <template v-slot:body="{ items }">
        <tbody>
          <tr
            v-for="(item, index) in items"
            :key="index"
            :class="[
              $vuetify.breakpoint.xsOnly
                ? 'v-data-table__mobile-table-row'
                : '',
              index % 2 == 1 ? 'amber lighten-5' : '',
            ]"
          >
            <td
              :class="
                $vuetify.breakpoint.xsOnly ? 'v-data-table__mobile-row' : ''
              "
            >
              <div
                class="
                  font-weight-bold
                  mr-2
                  v-data-table__mobile-row__header
                  d-flex d-sm-none
                "
              >
                {{ billingEventHeader[0].text }}
              </div>
              <div
                :class="
                  $vuetify.breakpoint.xsOnly
                    ? 'v-data-table__mobile-row__cell'
                    : 'd-flex justify-left'
                "
              >
                {{ item.createdAt.substr(0, 10) }}
              </div>
            </td>
            <td
              :class="
                $vuetify.breakpoint.xsOnly ? 'v-data-table__mobile-row' : ''
              "
            >
              <div
                class="
                  font-weight-bold
                  mr-2
                  v-data-table__mobile-row__header
                  d-flex d-sm-none
                "
              >
                {{ billingEventHeader[1].text }}
              </div>
              <div
                :class="
                  $vuetify.breakpoint.xsOnly
                    ? 'v-data-table__mobile-row__cell'
                    : 'd-flex justify-center'
                "
              >
                {{ item.itemQuantity }}
              </div>
            </td>
            <td
              :class="
                $vuetify.breakpoint.xsOnly ? 'v-data-table__mobile-row' : ''
              "
            >
              <div
                class="
                  font-weight-bold
                  mr-2
                  v-data-table__mobile-row__header
                  d-flex d-sm-none
                "
              >
                {{ billingEventHeader[2].text }}
              </div>
              <div
                :class="
                  $vuetify.breakpoint.xsOnly
                    ? 'v-data-table__mobile-row__cell'
                    : 'd-flex justify-left'
                "
              >
                {{ item.paymentInformation.name}} {{item.paymentInformation.lastName}}
              </div>
            </td>
            <td
              :class="
                $vuetify.breakpoint.xsOnly ? 'v-data-table__mobile-row' : ''
              "
            >
              <div
                class="
                  font-weight-bold
                  mr-2
                  v-data-table__mobile-row__header
                  d-flex d-sm-none
                "
              >
                {{ billingEventHeader[3].text }}
              </div>
              <div
                :class="
                  $vuetify.breakpoint.xsOnly
                    ? 'v-data-table__mobile-row__cell'
                    : 'd-flex justify-left'
                "
              >
                {{ item.paymentInformation.email }}
              </div>
            </td>
            <td
              :class="
                $vuetify.breakpoint.xsOnly ? 'v-data-table__mobile-row' : ''
              "
            >
              <div
                class="
                  font-weight-bold
                  mr-2
                  v-data-table__mobile-row__header
                  d-flex d-sm-none
                "
              >
                {{ billingEventHeader[4].text }}
              </div>
              <div
                :class="
                  $vuetify.breakpoint.xsOnly
                    ? 'v-data-table__mobile-row__cell'
                    : 'd-flex justify-left'
                "
              >
                $1,000
              </div>
            </td>
            <td
              :class="
                $vuetify.breakpoint.xsOnly ? 'v-data-table__mobile-row' : ''
              "
            >
              <div
                class="
                  font-weight-bold
                  mr-2
                  v-data-table__mobile-row__header
                  d-flex d-sm-none
                "
              >
                {{ billingEventHeader[5].text }}
              </div>
              <div
                :class="
                  $vuetify.breakpoint.xsOnly
                    ? 'v-data-table__mobile-row__cell'
                    : 'd-flex justify-left'
                "
              >
                <span
                  v-if="item.status == 'COMPLETED'"
                  class="green--text font-weight-bold"
                  > COMPLETADO</span
                >
                <span
                  v-else-if="item.status == 'IN_PROGRESS'"
                  class="blue--text font-weight-bold"
                  >PROGRESO</span
                >
                <span v-else class="red--text font-weight-bold">{{
                  item.state
                }}</span>
              </div>
            </td>
            <td
              :class="
                $vuetify.breakpoint.xsOnly ? 'v-data-table__mobile-row' : ''
              "
            >
              <div
                class="
                  font-weight-bold
                  mr-2
                  v-data-table__mobile-row__header
                  d-flex d-sm-none
                "
              >
                {{ billingEventHeader[6].text }}
              </div>
              <div
                :class="
                  $vuetify.breakpoint.xsOnly
                    ? 'v-data-table__mobile-row__cell'
                    : 'd-flex justify-center'
                "
              >
                {{ item.paymentInformation.paymentMethod=="CREDIT_CARD"?"TARJETA":item.paymentInformation.paymentMethod=="BANK_TRANSFER"?"SPEI":"EFECTIVO" }}
              </div>
            </td>
            <td
              :class="
                $vuetify.breakpoint.xsOnly ? 'v-data-table__mobile-row' : ''
              "
            >
              <div
                class="
                  font-weight-bold
                  mr-2
                  v-data-table__mobile-row__header
                  d-flex d-sm-none
                "
              >
                {{ billingEventHeader[7].text }}
              </div>
              <div
                :class="
                  $vuetify.breakpoint.xsOnly
                    ? 'v-data-table__mobile-row__cell'
                    : 'd-flex justify-left'
                "
              >
                <v-btn v-if="item.generatedBill" color="accent" rounded>
                  DESCARGAR
                </v-btn>
                <!--<v-btn
                  v-else
                  :disabled="isInvalidDate(item.createdAt.substr(0, 10))"
                  color="secondary"
                  rounded
                  @click="billingDialog = true"
                >
                  FACTURAR
                </v-btn>-->
                <v-btn
                  v-else
                  color="secondary"
                  rounded
                  @click="billingDialog = true"
                >
                  FACTURAR
                </v-btn>
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
    <v-dialog v-model="billingDialog" width="960">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="12" class="mt-2">
              <p class="text-center font-weight-bold headline mb-5 mt-5">
                Generar Factura
              </p>
            </v-col>
          </v-row>

          <v-form ref="billingForm" v-model="billingFormValid" lazy-validation>
            <v-row class="px-sm-6">
              <v-col cols="12" md="6" class="py-0">
                <v-text-field
                  v-model="billingData.transactionId"
                  label="Id de transacción"
                  filled
                  rounded
                  :rules="billinIdRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-text-field
                  v-model="billingData.email"
                  label="E-mail"
                  filled
                  rounded
                  :rules="emailRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-text-field
                  v-model="billingData.socialReason"
                  label="Razón Social Nombre Fiscal"
                  filled
                  rounded
                  :rules="socialReasonRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-text-field
                  v-model="billingData.rfc"
                  label="RFC"
                  filled
                  rounded
                  :rules="rfcRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-text-field
                  v-model="billingData.adress"
                  label="Dirección"
                  filled
                  rounded
                  :rules="adressRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-text-field
                  v-model="billingData.postalCode"
                  label="Código postal"
                  filled
                  rounded
                  :rules="postalCodeRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-select
                  v-model="billingData.paymentForm"
                  label="Forma de pago"
                  filled
                  rounded
                  :items="paymentFormsList"
                  :rules="paymentFormRules"
                ></v-select>
              </v-col>

              <v-col cols="12" md="6" class="py-0">
                <v-select
                  v-model="billingData.paymentMethod"
                  label="Método de pago"
                  filled
                  rounded
                  :items="paymentMethodsList"
                  :rules="paymentMethodRules"
                ></v-select>
              </v-col>
              <v-col cols="12"  class="py-0">
                <v-select
                  v-model="billingData.cfdi"
                  label="Uso de CFDI"
                  filled
                  rounded
                  :items="cfdiList"
                  :rules="cfdiRules"
                ></v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey lighten-3" class="font-weight-bold black--text mb-3" @click="billingDialog = false" rounded elevation="0">
            Cancelar
          </v-btn>
          <v-btn color="accent" class="font-weight-bold black--text mb-3 mx-3" @click="validateBillingForm" rounded elevation="0"> Aceptar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { validations } from "@/share/validations.js";
import {mapState, mapActions} from "vuex";
export default {
  computed: {
    ...mapState("purchase",["purchasesData"]),
  },
  data() {
    return {
      billingDialog: false,
      billingLoader: false,
      billingFormValid: false,
      billingData: {
        transactionId: "",
        socialReason: "",
        rfc: "",
        adress: "",
        postalCode: "",
        paymentForm: "",
        paymentMethod: "",
        cfdi: "",
        email: "",
      },
      paymentMethodsList: [
        "PUE Pago en una sola exhibición",
        "NA",
      ],
      paymentFormsList: [
        "01 Efectivo",
        "03 Transferencia electrónica de fondos ( Incluye SPEI)",
        "04 Tarjeta de crédito",
        "28 Tarjeta de débito",
      ],
      cfdiList: [
        "P01 Por Definir.",
        "G03 Gastos en general",
        "D01 Honorarios médicos, dentales y gastos hopitalarios.",
        "D02 Gastos médicos por incapacidad o discapacidad.",
        "D04 Donativos.",
        "D05 Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación).",
        "D06 Aportaciones voluntarias al SAR.",
        "D07 Primas por seguros de gastos médicos.",
        "D08 Gastos de transportación escolar obligatoria.",
        "D09 Depósitos en cuenta para el ahorro de primas que tengan como base planes de pensiones.",
        "D10 Pagos por servicios educativos (colegiaturas)",
        "G02 Devoluciones o Descuentos o Bonificaciones",
        "I01 Construcciones.",
        "I02 Mobiliario y equipo de oficina por inversiones.",
        "I03 Equipo de transporte.",
        "I05 Dados, troqueles, moldes, maherramental.",
        "I06 Comunicaciones telefónicas.",
        "I07 Comunicaciones satelitales.",
        "I08 Otra maquinaria y equipo.",
      ],

      //Validaciones
      billinIdRules: validations.generalMValidation({
        required: true,
        name: "id de la transacción",
      }),
      socialReasonRules: validations.generalFValidation({
        required: true,
        name: "razón social",
      }),
      rfcRules: validations.generalMValidation({
        required: true,
        name: "RFC",
      }),
      adressRules: validations.generalFValidation({
        required: true,
        name: "dirección",
      }),
      postalCodeRules: validations.generalMValidation({
        required: true,
        name: "código postal",
      }),
      paymentFormRules: validations.generalFValidation({
        required: true,
        name: "forma de pago",
      }),
      paymentMethodRules: validations.generalMValidation({
        required: true,
        name: "método de pago",
      }),
      cfdiRules: validations.generalMValidation({
        required: true,
        name: "CFDI",
      }),
      emailRules: validations.generalMValidation({
        required: true,
        name: "e-mail",
      }),

      billingEventHeader: [
        {
          text: "Fecha",
          align: "left",
          value: "date",
          width: "10%",
        },
        {
          text: "Cantidad créditos",
          align: "center",
          value: "quantity",
          width: "10%",
        },
        {
          text: "Nombres y Apellidos",
          align: "left",
          value: "name",
          width: "10%",
        },
        {
          text: "Correo electrónico",
          align: "left",
          value: "email",
          width: "10%",
        },
        {
          text: "Total",
          align: "left",
          value: "total",
          width: "10%",
        },
        {
          text: "Estado",
          align: "left",
          value: "state",
          width: "10%",
        },
        {
          text: "Método de pago",
          align: "center",
          value: "payment",
          width: "10%",
        },
        {
          text: "Facturas",
          align: "center",
          value: "billing",
          width: "10%",
        },
      ],
      billingEvents: [
        {
          id: "1",
          date: "2021-10-17",
          quantity: "1,500",
          name: "FERNANDO CASTELLO LOPEZ",
          email: "fcastellopez@gmail.com",
          total: "$ 9,400",
          state: "PENDIENTE",
          payment: "TC",
          generatedBill: false,
        },
        {
          id: "2",
          date: "2021-10-07",
          quantity: "1,500",
          name: "FERNANDO CASTELLO LOPEZ",
          email: "fcastellopez@gmail.com",
          total: "$ 9,400",
          state: "APROBADA",
          payment: "TC",
          generatedBill: true,
        },
        {
          id: "3",
          date: "2021-09-17",
          quantity: "1,500",
          name: "FERNANDO CASTELLO LOPEZ",
          email: "fcastellopez@gmail.com",
          total: "$ 9,400",
          state: "RECHAZADA",
          payment: "TC",
          generatedBill: true,
        },
        {
          id: "4",
          date: "2021-09-23",
          quantity: "1,500",
          name: "FERNANDO CASTELLO LOPEZ",
          email: "fcastellopez@gmail.com",
          total: "$ 9,400",
          state: "APROBADA",
          payment: "TC",
          generatedBill: false,
        },
      ],
    };
  },
  methods: {
    ...mapActions("purchase", ["fetchOrganizationPurchases"]),
    isInvalidDate(transactionDate) {
      let date = new Date();
      let firstDate = new Date(date.getFullYear(), date.getMonth(), 1);
      let lastDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      let currentDate = new Date(transactionDate);
      if (currentDate >= firstDate && currentDate <= lastDate) {
        return false;
      } else {
        return true;
      }
    },
    validateBillingForm() {
      if (this.$refs.billingForm.validate()) {
        this.snackbar = true;
        return true;
      }
      return false;
    },
  },
  async mounted() {
    await this.fetchOrganizationPurchases();
    //console.log("Dataaaaa");
    //console.log(this.purchasesData);
  }
};
</script>
<style scoped>
  table, tr, td{
    border:none !important;
  }
</style>